import React from 'react';
import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Commercial Solar" img="card1.png" text="Commercial clients see a 72% reduction in the cost of electricity per year." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Residential Solar" img="card2.png" text="Independent houses practically get off the grid and rely fully on solar, bringing their electricity bill down to ZERO." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Solar charging stations" img="card3.png" text="By using a solar-powered charging station, you prevent 3500 kgs of CO2 from being emitted per car per year." />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;

import React from 'react';

function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="./img/img1.png" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT</h2>
            <p className="main-p">
              Started in August of 2024, we set out with the goal of having 1 in every 3 houses and 1 in every 2 office buildings powered by solar energy by the end of 2030. We are an energy company that strives to operate at net zero carbon emissions, and our activities range from rooftop solar for residential and commercial properties to solar-powered EV charging stations. 
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;

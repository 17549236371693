import React, { useState } from 'react';
import { init, send } from 'emailjs-com'; // Import the emailjs-com library

init("d1zH6UNPIGfFtdCEu"); // Replace with your MailJS user ID

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Use MailJS to send the email
    send('service_9uk4fgz', 'template_uuwsowl', formData)
      .then((response) => {
        console.log('Enquiry recieved', response.status, response.text);
        // Optionally, reset the form after successful submission
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
        alert('You will hear from us soon');
      })
      .catch((err) => {
        console.error('Failed to send message', err);
        alert('Failed to send message, please try again.');
      });
  };

  return (
    <div className="container contact">
      <h2 className="main-title text-center">ENQUIRE</h2>
      <form onSubmit={handleSubmit}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-1">
              <input 
                name="name" 
                placeholder="Name" 
                className="contact-input" 
                value={formData.name}
                onChange={handleChange} 
                required 
              />
            </div>

            <div className="col-md-4 mb-1">
              <input 
                name="email" 
                placeholder="Email" 
                className="contact-input" 
                value={formData.email}
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="col-md-4 mb-1">
              <input 
  type="tel"
  name="subject" 
  placeholder="Phone Number" 
  className="contact-input" 
  value={formData.subject}
  onChange={handleChange} 
  pattern="[0-9]{10}"  /* Ensures 10-digit phone number */
  title="Please enter a valid 10-digit phone number"
  required 
/>

            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12">
          <textarea
            name="message"
            placeholder="Home Address"
            className="contact-textarea"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <br />
        <div className="row">
          <div className="col-md-12">
            <input className="form-btn" type="submit" value="Enquire" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contact;

import React, { useEffect, useRef } from 'react';

function Header() {
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.log('Video play failed: ', error);
        }
      }
    };

    playVideo();
  }, []);

  return (
    <header style={{ fontFamily: "'Poppins', sans-serif" }}>
      <video 
        ref={videoRef} 
        src="./video.mp4" 
        loop 
        autoPlay 
        muted 
        playsInline // This attribute is important for mobile devices
      />
      <h1>Lunar Electric</h1>
      <div className="row"></div>
      <div className="headerbg"></div>
    </header>
  );
}

export default Header;


import { Link, animateScroll as scroll } from 'react-scroll';
import React from 'react';

// Import the font inside the component
const fontLink = document.createElement('link');
fontLink.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap';
fontLink.rel = 'stylesheet';
document.head.appendChild(fontLink);

function Footer() {
  return (
    <footer style={{ fontFamily: "'Poppins', sans-serif" }}>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">Lunar Electric</h1>
                <p className="footer-text">
                  All rights reserved<sup>®</sup>
                </p>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Important Link</p>
                <ul>
                  <li>
                    <Link spy={true} smooth={true} duration={1000} to="headerbg">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="services" spy={true} smooth={true} duration={1000}>
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="about-scroll" spy={true} smooth={true} duration={1000}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="contact" spy={true} smooth={true} duration={1000}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <a href="mailto:ronak@lunarelectric.in">ronak@lunarelectric.in</a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/ronakpoojara"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ronak Poojara
                    </a>
                  </li>
                  <li>
                    <Link to="#"> +91 9148857417</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://github.com/ronakpoojara17">
                      Github
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ronakpoojara">
                      Linkedin
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} className="gotop">
        <i className="fas fa-level-up-alt"></i>
      </button>
    </footer>
  );
}

export default Footer;
